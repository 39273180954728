import ActionNode = require("Everlaw/UI/ActionNode");
import Arr = require("Everlaw/Core/Arr");
import Base = require("Everlaw/Base");
import { IconButton } from "Everlaw/UI/Button";
import Cmp = require("Everlaw/Core/Cmp");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import HomepageFolder = require("Everlaw/HomepageFolder");
import Icon = require("Everlaw/UI/Icon");
import Is = require("Everlaw/Core/Is");
import Perm = require("Everlaw/PermissionStrings");
import Project = require("Everlaw/Project");
import Recipient = require("Everlaw/Recipient");
import Rest = require("Everlaw/Rest");
import Security = require("Everlaw/Security");
import ShareableObject = require("Everlaw/Sharing/ShareableObject");
import Table = require("Everlaw/Table");
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");
import { ProjectRole } from "Everlaw/Security";
import Tooltip = require("Everlaw/UI/Tooltip");
import DateUtil = require("Everlaw/DateUtil");

//
// These tables are used to view/edit the permissions on shareable objects. Each row represents a
// permission holder's access to a shareable object. ("Permission holder" is a broad term here that
// could mean a traditional sharing recipient like a user/group/org/role, or a project-level
// permission like Storybuilder admins, for example.)
//
// These permission tables fall into two basic categories:
//
// - Object tables, which show the permissions for a specific shareable object. Each row shows a
//   different permission holder's access to that object. There are a few variants of these tables,
//   depending on the type of object (e.g., whether it can be shared via a homepage folder).
//
// - Recipient tables, which show all the objects a particular recipient has permissions on. Each
//   row shows the recipient's access to a different shareable object (or class of objects).
//
// All tables have:
//
// - A "name" column with a description of the row.
//
// - A "direct permission" column containing the directly-shared permissions of the object. If this
//   is editable, a select is used. Otherwise, the permission is just displayed in text.
//
// - A "revoke" column with a trash icon for revoking the permission. Revoking may be disabled in
//   some cases.
//
// The design used here is to create classes for each column type that encapsulate all the logic of
// that column. A Base.Primitive "row" class is then used as the object store for the table, and
// contains references to all of the column class instances for that row. Finally, a class for the
// table itself contains the logic for creating entries, building the DOM (including the table
// widget itself), sorting the table, handling edits, etc.
//

// ************************************
//
// Some base classes used by all table types.
//
// ************************************

interface BaseTableCellParams {
    // Whether the cell content should initially be disabled.
    disabled?: boolean;
    // Whether the cell content should initially be hidden.
    hideContent?: boolean;
}

/**
 * Base class for a table cell. The DOM elements are not created until the cellCallback() method is
 * called during the table widget's construction.
 */
abstract class BaseTableCell {
    disabled: boolean;
    protected hideContent: boolean;
    protected td: HTMLTableCellElement; // set by cellCallback()
    protected toDestroy: Util.Destroyable[] = [];
    constructor(params: BaseTableCellParams) {
        this.disabled = !!params.disabled;
        this.hideContent = !!params.hideContent;
    }
    protected abstract buildContent(): void;
    cellCallback(p: Table.CellCallbackParam<BaseTableRow, Table.RowData>): void {
        if (p.firstTime) {
            this.td = p.td;
            Dom.addClass(this.td, "shareable-object-perms-table-cell");
            this.setDisabled(this.disabled);
            p.data.destroyables.push(this);
            this.buildContent();
        }
    }
    setDisabled(disabled = true): void {
        this.disabled = disabled;
        Dom.toggleClass(this.td, "shareable-object-perms-table-cell--disabled", disabled);
    }
    updateVisibility(show = !this.hideContent): void {
        this.hideContent = !show;
    }
    destroy(): void {
        Util.destroy(this.toDestroy);
    }
}

interface NameCellParams extends BaseTableCellParams {
    name: string;
}

/**
 * A cell used for displaying one of the following (depending on the table):
 * - an sid (user/group/role/org)
 * - a project-level admin permission (e.g., "Storybuilder admins")
 * - a category of shareable objects (e.g., "Assignment Groups")
 * - an actual shareable object (depo, draft, STR, etc.)
 */
class NameCell extends BaseTableCell {
    name: string;
    protected container: HTMLElement;
    constructor(params: NameCellParams) {
        super(params);
        this.name = params.name;
    }
    protected buildContent(): void {
        Dom.addClass(this.td, "name-column");
        Dom.place(
            (this.container = Dom.div(
                { class: "name-column__container" },
                Dom.div({ class: "name-column__name" }, this.name),
            )),
            this.td,
        );
    }
}

interface PermissionCellParams extends BaseTableCellParams {
    // If not specified, the cell will be empty.
    securityMap?: ShareableObject.SecurityMap;
    // Index of the initial permission to display. Only valid when securityMap is specified.
    // A value of -1 corresponds to "no access" and can only be used when onChange isn't specified.
    initialIndex?: number;
    // If not specified, the permission won't be editable.
    onChange?: (info: ShareableObject.SecurityInfo, index: number) => void;
}

/**
 * A cell containing either a permission selector or a single permission's string (in the case where
 * the permission isn't editable). The cell can also be empty if there is no permission to display.
 */
class PermissionCell extends BaseTableCell {
    securityMap: ShareableObject.SecurityMap;
    curIndex: number;
    editable: boolean;
    private onChange: (info: ShareableObject.SecurityInfo, index: number) => void;
    private selector: ShareableObject.PermSelector;
    private displaySpan: HTMLElement;
    constructor(params: PermissionCellParams = {}) {
        super(params);
        this.onChange = params.onChange;
        this.setState(params.securityMap, params.initialIndex);
    }
    protected buildContent(): void {
        Dom.addClass(this.td, "permissions-column");
        this._buildContent();
    }
    /**
     * Method containing the logic for building the content of the cell. This can be called
     * multiple times as the content of the cell may need to change (or be cleared) as the user
     * makes edits to other permissions in the row.
     */
    private _buildContent(): void {
        Util.destroy(this.selector);
        Dom.empty(this.td);
        this.selector = null;
        this.displaySpan = null;
        if (this.securityMap) {
            const elem = this.curIndex >= 0 ? this.securityMap.elems[this.curIndex] : null;
            if (this.editable) {
                this.selector = new ShareableObject.PermSelector({
                    parent: this.td,
                    securityMap: this.securityMap,
                    onChange: (info, index) => this.onChange(info, index),
                    default: elem,
                });
            } else {
                const display = elem
                    ? elem.display() + (this.disabled ? " (inactive)" : "")
                    : "No access";
                Dom.place((this.displaySpan = Dom.span(display)), this.td);
                Dom.addClass(this.td, "permissions-column__single");
            }
        }
        this.updateVisibility();
    }
    private setState(securityMap: ShareableObject.SecurityMap, initialIndex = -1): void {
        this.securityMap = securityMap;
        this.curIndex = initialIndex;
        // Permissions are only editable if the security map has multiple entries and an onChange
        // function was specified.
        this.editable = !!this.securityMap && this.securityMap.elems.length > 1 && !!this.onChange;
    }
    /** Rebuilds the content of the cell based on the updated state. */
    rebuild(securityMap: ShareableObject.SecurityMap, initialIndex = -1): void {
        this.setState(securityMap, initialIndex);
        this._buildContent();
    }
    clearContents(): void {
        this.rebuild(null);
    }
    /** Reset the selected element to the current index. Used when a request fails. */
    resetSelected(): void {
        this.selector.setSelected(this.curIndex);
    }
    override updateVisibility(show = !this.hideContent): void {
        super.updateVisibility(show);
        this.displaySpan && Dom.show(this.displaySpan, show);
        this.selector && Dom.show(this.selector, show);
    }
    override destroy(): void {
        super.destroy();
        Util.destroy(this.selector);
    }
}

interface RevokeCellParams extends BaseTableCellParams {
    // If not specified, the icon will automatically be disabled (or the cell will be created empty
    // if disabledTooltip is not specified).
    onRevoke?: () => void;
    // Defaults to "Revoke permissions".
    tooltip?: string;
    // If not specified and onRevoke is also not specified, the cell will be empty.
    disabledTooltip?: string;
}

/**
 * A cell containing a trash can for revoking permissions. The trash can be disabled, or the cell
 * can be empty if no trash is needed.
 */
class RevokeCell extends BaseTableCell {
    private trash: IconButton;
    private onRevoke: () => void;
    private tooltip: string;
    private disabledTooltip: string;
    constructor(params: RevokeCellParams = {}) {
        super(params);
        this.onRevoke = params.onRevoke;
        this.tooltip = params.tooltip || "Revoke permissions";
        this.disabledTooltip = params.disabledTooltip;
    }
    protected buildContent(): void {
        Dom.addClass(this.td, "revoke-column");
        if (this.onRevoke || this.disabledTooltip) {
            this.toDestroy.push(
                (this.trash = new IconButton({
                    iconClass: "trash-20",
                    showTooltipOnDisable: true,
                    parent: this.td,
                    tooltip: this.getTooltip(),
                    onClick: () => this.onRevoke(),
                })),
            );
            this.setDisabled(this.disabled);
        }
        this.updateVisibility();
    }
    clearContents(): void {
        Util.destroy(this.trash);
        this.trash = null;
        Dom.empty(this.td);
    }
    override setDisabled(disabled: boolean): void {
        super.setDisabled(disabled);
        if (this.trash) {
            this.trash.setDisabled(disabled);
            this.trash.tooltip.setContent(this.getTooltip());
        }
    }
    private getTooltip(): string {
        return (this.disabled && this.disabledTooltip) || this.tooltip;
    }
    override updateVisibility(show = !this.hideContent): void {
        super.updateVisibility(show);
        this.trash && Dom.show(this.trash, show);
    }
}

// Base primitives need unique IDs, so we use an incrementing counter.
let rowId = 0;

/**
 * Base row class used for the table's object store. Contains references to each cell in the row.
 * All tables have at least a name column, a direct permission column, and a revoke column.
 */
class BaseTableRow extends Base.Primitive<number> {
    nameCell: NameCell;
    directPermCell: PermissionCell;
    revokeCell: RevokeCell;
    constructor(public sortKey = 0) {
        super(rowId++);
    }
}

/** Interface used by table classes for storing info about each column. */
interface TableColumnInfo {
    header: Dom.Content;
    props: any; // This is from Table.TableParams.columns, so need to keep 'any' here.
    cellCallback: Table.CellCallback<BaseTableRow, Table.RowData>;
}

// Placeholder extended by subclass params. The empty interface is needed because the params
// structure is passed to the init() method which is overridden by subclasses.
interface BaseTableParams {}

/**
 * The base table class. Specifies methods for creating rows, column info, and the table itself, as
 * well as row filtering and comparisons.
 */
abstract class BaseTable {
    node: HTMLElement;
    private tableHeading: HTMLElement;
    protected tableContainer: HTMLElement;
    protected hasProjectAdmin: boolean;
    protected rows: BaseTableRow[] = [];
    protected table: Table<BaseTableRow, Table.RowData>;
    protected columnInfo: TableColumnInfo[] = [];
    protected revoked: Set<BaseTableRow> = new Set();
    protected toDestroy: Util.Destroyable[] = [];
    constructor(params: BaseTableParams) {
        this.init(params);
        this.createRows();
        this.createColumnInfo();
        this.table = new Table(this.getTableParams());
        Dom.place(this.table, this.tableContainer);
        if (this.table.navigation) {
            Dom.place(this.table.navigation, this.tableContainer);
        }
    }
    /**
     * Init method for subclasses to override when work needs to be done before the table is
     * created.
     */
    protected init(_params: BaseTableParams): void {
        this.node = Dom.div(
            { class: "shareable-object-perm-table" },
            (this.tableHeading = Dom.div({ class: "shareable-object-perm-table__heading hidden" })),
            (this.tableContainer = Dom.div({ class: "shareable-object-perm-table__table" })),
        );
        this.hasProjectAdmin = User.me.can(
            Perm.ADMIN,
            Project.CURRENT,
            User.Override.ELEVATED_OR_ORGADMIN,
        );
    }
    protected setHeading(content: Dom.Content) {
        Dom.setContent(this.tableHeading, content);
        Dom.show(this.tableHeading);
    }
    /** Method to initialize this.rows. */
    protected abstract createRows(): void;
    /** Method to initialize this.columnInfo. */
    protected abstract createColumnInfo(): void;
    /** Method to build the parameters passed to the table constructor. */
    protected getTableParams(): Table.TableParams<BaseTableRow, Table.RowData> {
        return {
            store: this.rows.length ? Base.constantStore(this.rows) : Base.emptyStore("Primitive"),
            columns: this.columnInfo.map((info) => info.props),
            cells: this.columnInfo.map((info) => info.cellCallback),
            header: this.columnInfo.map((info) => info.header),
            compare: (row1, row2) => this.rowCmp(row1, row2),
            filter: (row) => this.rowFilter(row),
            skipCallout: true,
            empty: "No permissions",
        };
    }
    /** Callback to handle a change to a recipient's direct permissions (via the select). */
    protected onDirectPermChange(
        objClass: string,
        objId: string | number,
        sid: string,
        row: BaseTableRow,
        index: number,
    ): void {
        ShareableObject.setPerms(
            objClass,
            objId,
            sid,
            row.directPermCell.securityMap.elems[index].id,
        )
            .then(() => this.onDirectPermChangeComplete(row, index))
            .catch((reason: Rest.Failed) => this.onPermChangeError(reason, row.directPermCell));
    }
    /** Callback when the backend permission change has succeeded. */
    protected onDirectPermChangeComplete(row: BaseTableRow, index: number): void {
        // Update the cell's current index to match the selector.
        row.directPermCell.curIndex = index;
    }
    /** Handle a backend error when changing permissions. */
    protected onPermChangeError(reason: Rest.Failed, permCell: PermissionCell) {
        BaseTable.showErrorDialog(reason);
        // Reset the state of the select to what it was before the operation was attempted.
        permCell.resetSelected();
    }
    /** Callback to handle the revoking of a recipient's direct permissions (via the trash icon). */
    protected onDirectPermRevoke(
        objClass: string,
        objId: string | number,
        sid: string,
        row: BaseTableRow,
    ): void {
        ShareableObject.revokePerms(objClass, objId, sid)
            .then(() => this.onRevokeComplete(row))
            .catch((reason: Rest.Failed) => BaseTable.showErrorDialog(reason));
    }
    /**
     * Callback when the backend permission revoke has succeeded. Returns true if the row was
     * hidden from the table.
     */
    protected onRevokeComplete(row: BaseTableRow): boolean {
        if (this.shouldDeleteOnRevoke(row)) {
            this.revoked.add(row);
            this.table.update([row]);
            return true;
        }
        // If the row is not being deleted, clear the permissions cell and trash.
        row.directPermCell.clearContents();
        row.revokeCell.clearContents();
        return false;
    }
    /** Returns true if the row should be hidden from the table when permissions are revoked. */
    protected shouldDeleteOnRevoke(_row: BaseTableRow): boolean {
        return true;
    }
    /** Filter method used by the table widget. */
    protected rowFilter(row: BaseTableRow): boolean {
        return !this.revoked.has(row);
    }
    protected rowCmp(row1: BaseTableRow, row2: BaseTableRow): number {
        return Cmp.num(row1.sortKey, row2.sortKey);
    }
    /**
     * Dialog shown for backend request failures. Most of the time this indicates a bug, as the
     * frontend generally should not make an invalid request. However, there are a couple
     * exceptions. For example, if the user only has admin access to the object through a user
     * group, and they try to revoke that user group's permissions on the object (thereby revoking
     * their own permissions on the object), we do not check for that on the frontend. Instead, we
     * just let the backend throw the error.
     */
    static showErrorDialog(reason: Rest.Failed): void {
        Dialog.ok("Error", [Dom.p("The operation could not be completed:"), Dom.p(reason.message)]);
    }
    destroy(): void {
        Util.destroy(this.toDestroy);
    }
}

// ************************************
//
// An "object table" shows all the permissions granted for a specific ShareableObject. The rows
// represent either a (non-editable) project-level permission, or an sid (user/group/role/org).
//
// ************************************

/**
 * The order in which permission holders are displayed in an object table. We use the BaseTableRow's
 * sortKey variable to store this value.
 */
enum ObjectTableNameSortKey {
    OWNER,
    PROJECT_PERMISSION,
    PROJECT_READ,
    GROUPS_USERS_AND_ORGS,
}

/** A name cell used for project-level permisions ("[Storybuilder admins]", etc.). */
class ProjectPermNameCell extends NameCell {
    constructor(permName: string) {
        super({ name: `[All ${permName} admins]` });
    }
}

/** A name cell used for displaying an sid (user/group/role/org). */
class SidNameCell extends NameCell {
    constructor(
        public sid: string,
        public isOwner = false,
    ) {
        super({ name: ShareableObject.displaySid(sid) });
    }
    protected override buildContent(): void {
        super.buildContent();
        this.isOwner
            && Dom.place(Dom.div({ class: "name-column__owner" }, "OWNER"), this.container);
    }
}

/** An object table row, used as the object store for the table. */
class ObjectTableRow extends BaseTableRow {
    // Folderable objects have a folderSharePermCell showing the folder permission, and a
    // summaryPermCell showing the greater of the direct permission and the folder permission.
    folderSharePermCell: PermissionCell;
    summaryPermCell: PermissionCell;
    // Homepage folders have a folderObjectPermCell showing permission on the objects in the folder.
    folderObjectPermCell: PermissionCell;
    /**
     * For folderable objects, computes the summary permission to display based on the direct
     * permissions and folder permissions.
     */
    getSummaryPermIndex(): number {
        let index = this.directPermCell.curIndex;
        // Don't include the folder share permission in the summary if it's disabled.
        if (!this.folderSharePermCell.disabled) {
            index = Math.max(index, this.folderSharePermCell.curIndex);
        }
        return index;
    }
}

/** An object table row for showing project-level permissions. */
class ProjectPermRow extends ObjectTableRow {
    override nameCell: ProjectPermNameCell;
    constructor() {
        super(ObjectTableNameSortKey.PROJECT_PERMISSION);
    }
}

/** An object table row for showing an sid's permissions. */
class SidRow extends ObjectTableRow {
    override nameCell: SidNameCell;
    recipient: Recipient;
    constructor(
        public sid: string,
        public isOwner = false,
    ) {
        super();
        this.recipient = ShareableObject.toRecipientEbo(this.sid);
        if (this.sid === ProjectRole.PROJECT_READ) {
            this.sortKey = ObjectTableNameSortKey.PROJECT_READ;
        } else {
            this.sortKey = isOwner
                ? ObjectTableNameSortKey.OWNER
                : ObjectTableNameSortKey.GROUPS_USERS_AND_ORGS;
        }
    }
    isMe(): boolean {
        return this.recipient === User.me;
    }
}

/** Interface used for storing info about each object table column. */
interface ObjectTableColumnInfo extends TableColumnInfo {
    cellCallback: Table.CellCallback<ObjectTableRow, Table.RowData>;
}

interface BaseObjectTableParams extends BaseTableParams {
    obj: Base.Object;
    security: ShareableObject.ObjectSecurity;
}

/**
 * Base class for an object table.
 */
abstract class BaseObjectTable extends BaseTable {
    protected override rows: ObjectTableRow[];
    protected override table: Table<ObjectTableRow, Table.RowData>;
    protected override columnInfo: ObjectTableColumnInfo[];
    protected obj: Base.Object;
    protected classInfo: ShareableObject.ClassInfo;
    protected securityMap: ShareableObject.SecurityMap;
    protected security: ShareableObject.ObjectSecurity;
    protected permsChanged = false;
    protected dialogWidth = 815;
    protected static nameColumnWidth = 325;
    protected static noOwnerRevokeTooltip = "You cannot revoke the owner's permissions";
    protected static noSelfRevokeTooltip = "You cannot revoke your own permissions";
    constructor(params: BaseObjectTableParams) {
        super(params);
        this.createDialog();
    }
    protected override init(params: BaseObjectTableParams): void {
        super.init(params);
        Dom.addClass(this.tableContainer, "object-table");
        this.obj = params.obj;
        this.classInfo = ShareableObject.getClassInfo(this.obj);
        this.securityMap = this.classInfo.securityMap; // for shorthand
        this.security = params.security;
    }
    protected createRows(): void {
        // If this object is controlled by a project-level permission, or if it is accessible by
        // all project admins, create an admin row.
        const defaultProjectAdminAccess =
            this.classInfo.projectPermissionName || this.classInfo.securityMap.projectAdminAccess;
        if (defaultProjectAdminAccess) {
            this.createProjectPermRow();
        }
        Object.entries(this.security.sharedSecurity).forEach(([sid, perms]) => {
            // We should show project admin if the object is not accessible to admins by default
            if (
                !(defaultProjectAdminAccess && sid === ProjectRole.PROJECT_ADMIN)
                && this.shouldShowSid(sid, perms)
            ) {
                this.createSidRow(sid, perms);
            }
        });
    }
    /** Create the (non-editable) row showing project-level admin permissions. */
    protected createProjectPermRow(): ProjectPermRow {
        const row = new ProjectPermRow();
        row.nameCell = new ProjectPermNameCell(this.classInfo.projectPermissionName || "Project");
        row.directPermCell = new PermissionCell({
            securityMap: this.securityMap,
            initialIndex: this.securityMap.elems.length - 1,
        });
        row.revokeCell = new RevokeCell();
        this.rows.push(row);
        return row;
    }
    /** Create a row for the given sid with the given permissions. */
    protected createSidRow(sid: string, perms?: Base.PermList): SidRow {
        const row = new SidRow(sid, !!perms && Arr.contains(perms, "owner"));
        row.nameCell = new SidNameCell(sid, row.isOwner);
        if (!ShareableObject.hasPerms(perms)) {
            // Show an empty permission cell and no trash icon.
            row.directPermCell = new PermissionCell();
            row.revokeCell = new RevokeCell();
        } else {
            row.directPermCell = new PermissionCell({
                securityMap: this.securityMap,
                initialIndex: ShareableObject.getSecurityMapIndex(this.securityMap, perms),
                onChange: this.canEditSidPerms(row)
                    ? (_info, index) => {
                          this.onDirectPermChange(
                              this.obj.className,
                              this.obj.id,
                              row.nameCell.sid,
                              row,
                              index,
                          );
                      }
                    : null,
            });
            let disabledTooltip: string;
            if (row.isMe()) {
                disabledTooltip = BaseObjectTable.noSelfRevokeTooltip;
            } else if (row.isOwner && !this.canEditOwnerPerms()) {
                disabledTooltip = BaseObjectTable.noOwnerRevokeTooltip;
            }
            row.revokeCell = new RevokeCell({
                onRevoke: () => {
                    this.onDirectPermRevoke(this.obj.className, this.obj.id, row.nameCell.sid, row);
                },
                disabledTooltip,
                disabled: !!disabledTooltip,
            });
        }
        this.rows.push(row);
        return row;
    }
    /**
     * Returns true if the sid should be shown in the table. We suppress showing permissions granted
     * to inactive users and deleted groups.
     */
    protected shouldShowSid(sid: string, perms: Base.PermList): boolean {
        const recipient = ShareableObject.toRecipientEbo(sid);
        if (
            (recipient instanceof User && !recipient.isActiveUser())
            || (recipient instanceof User.Group && recipient.deleted)
        ) {
            return false;
        }
        return ShareableObject.hasPerms(perms);
    }
    /** Returns true if the user can edit the sid's object permissions. */
    protected canEditSidPerms(row: SidRow): boolean {
        // You can't edit your own permissions, and you can only edit owner permissions if you
        // pass the proper permission check.
        return !row.isMe() && (!row.isOwner || this.canEditOwnerPerms());
    }
    /** Returns true if the user can edit the object owner's permissions. */
    protected canEditOwnerPerms(): boolean {
        // By default, you can only change the owner's permissions if you are a project admin.
        return this.hasProjectAdmin;
    }
    protected override getTableParams(): Table.TableParams<ObjectTableRow, Table.RowData> {
        const params = <Table.TableParams<ObjectTableRow, Table.RowData>>super.getTableParams();
        params.maxHeight = "313px";
        params.pagination = {
            curPage: 0,
            entriesPerPage: 10, // Each row has a selector and delete icon, so use a conservative number
            additionalClass: "table-pagination-navbar--detached",
        };
        return params;
    }
    /**
     * Create the dialog with the table and show it. When the dialog is closed, we publish to the
     * ShareableObject.securityChangeChannel if any permission changes were made.
     */
    protected createDialog(): void {
        const dialog = new Dialog.SingleButton({
            title: this.classInfo.displayName + " permissions",
            content: Dom.div({ class: "shareable-object-perm-table-dialog" }, [
                Dom.h2(this.obj.display()),
                this.node,
            ]),
            style: { width: `${this.dialogWidth}px` },
            autofocus: false,
            buttonText: "Done",
            closable: false,
            onHide: () => {
                this.permsChanged && ShareableObject.securityChangeChannel.publish(this.obj);
                this.destroy();
            },
        });
        dialog.show();
    }
    protected override onDirectPermChangeComplete(row: SidRow, index: number): void {
        this.permsChanged = true;
        super.onDirectPermChangeComplete(row, index);
    }
    protected override onRevokeComplete(row: SidRow): boolean {
        this.permsChanged = true;
        return super.onRevokeComplete(row);
    }
    protected override rowCmp(row1: ObjectTableRow, row2: ObjectTableRow): number {
        // If the rows have the same sort key, use the name string.
        return super.rowCmp(row1, row2) || Cmp.str(row1.nameCell.name, row2.nameCell.name);
    }
}

/**
 * An object table for shareable objects that can't be added to homepage folders. This is the most
 * basic type of object table which just has the three standard columns (name, direct perm, revoke).
 */
class NonFolderableObjectTable extends BaseObjectTable {
    constructor(params: BaseObjectTableParams) {
        super(params);
    }
    protected override init(params: BaseObjectTableParams): void {
        super.init(params);
        Dom.addClass(this.tableContainer, "nonfolderable-object-table");
    }
    protected createColumnInfo() {
        this.columnInfo = [
            {
                header: "Name",
                props: { style: { width: BaseObjectTable.nameColumnWidth + "px" } },
                cellCallback: (p) => p.o.nameCell.cellCallback(p),
            },
            {
                header: "Permission",
                props: { style: { width: "140px" } },
                cellCallback: (p) => p.o.directPermCell.cellCallback(p),
            },
            {
                header: "Revoke",
                props: {},
                cellCallback: (p) => p.o.revokeCell.cellCallback(p),
            },
        ];
    }
}

/**
 * An object table for shareable objects that can be added to homepage folders. The table includes
 * a column for folder permissions and a "summary" column showing the union of the direct and folder
 * permissions. Initially, only the summary column is shown. The user has to click on an ActionNode
 * to show the detailed view of the table. If a recipient has folder permissions but not the
 * associated project-level RECEIVE permissions, their permissions are shown as "inactive" because
 * they can't actually see the object in the folder.
 */
class FolderableObjectTable extends BaseObjectTable {
    private editHeader: HTMLElement;
    constructor(params: BaseObjectTableParams) {
        super(params);
        // Initially show the basic view.
        this.showDetailed(false);
    }
    protected override init(params: BaseObjectTableParams): void {
        super.init(params);
        Dom.addClass(this.tableContainer, "folderable-object-table");
        const editAction = ActionNode.textAction(" Edit permissions", (_evt) =>
            this.showDetailed(true),
        );
        Dom.addClass(editAction, "folderable-object-table__edit");
        Dom.place(
            (this.editHeader = Dom.div(
                { class: "folderable-object-table__edit-header" },
                Dom.span(
                    { class: "folderable-object-table__via" },
                    "(via direct or folder share) ",
                ),
                Dom.node(editAction),
            )),
            this.tableContainer,
        );
        this.setHeading(
            "Only direct share permissions can be modified here. "
                + "Folder share permissions must be modified from the source folder's permission "
                + "settings on the Homepage.",
        );
    }
    protected override createRows(): void {
        super.createRows();
        // The superclass created rows for all recipients that have been directly shared the object,
        // but we need to create rows for any recipients that have been shared the object via a
        // folder but that don't have any direct sharing.
        if (this.security.sharedFolderSecurity) {
            Object.entries(this.security.sharedFolderSecurity).forEach(([sid, perms]) => {
                if (this.shouldShowSid(sid, perms)) {
                    const directPerms = this.security.sharedSecurity[sid];
                    if (!ShareableObject.hasPerms(directPerms)) {
                        this.createSidRow(sid, directPerms);
                    }
                }
            });
        }
    }
    protected override createProjectPermRow(): ProjectPermRow {
        const row = super.createProjectPermRow();
        row.folderSharePermCell = this.createFolderSharePermCell();
        row.summaryPermCell = this.createSummaryPermCell(row);
        return row;
    }
    protected override createSidRow(sid: string, perms: Base.PermList): SidRow {
        const row = super.createSidRow(sid, perms);
        row.folderSharePermCell = this.createFolderSharePermCell(row, this.getFolderSecurity(sid));
        row.summaryPermCell = this.createSummaryPermCell(row);
        return row;
    }
    private createFolderSharePermCell(row?: SidRow, folderPerms?: Base.PermList): PermissionCell {
        if (!row || !ShareableObject.hasPerms(folderPerms)) {
            // Empty cell
            return new PermissionCell();
        } else {
            let folderPermsActive = true;
            if (this.classInfo.projectPermissionName) {
                // If the sid doesn't have project-level receive permissions on the object class,
                // then they will not see these objects in any shared folders and so we consider
                // these permissions "inactive".
                if (row.recipient instanceof User) {
                    folderPermsActive = Arr.contains(
                        this.security.shareableUsers,
                        row.recipient.id,
                    );
                } else if (row.recipient instanceof User.Group) {
                    folderPermsActive = Arr.contains(
                        this.security.shareableGroups,
                        row.recipient.id,
                    );
                }
            }
            return new PermissionCell({
                securityMap: this.securityMap,
                initialIndex: ShareableObject.getSecurityMapIndex(this.securityMap, folderPerms),
                disabled: !folderPermsActive,
            });
        }
    }
    private createSummaryPermCell(row: ObjectTableRow): PermissionCell {
        return new PermissionCell({
            securityMap: this.securityMap,
            initialIndex: row.getSummaryPermIndex(),
        });
    }
    /** Show/hide the detailed view of the table, as opposed to just the summary column. */
    private showDetailed(show = true) {
        this.rows.forEach((row) => {
            row.directPermCell.updateVisibility(show);
            row.revokeCell.updateVisibility(show);
            row.folderSharePermCell.updateVisibility(show);
        });
        Dom.show(this.editHeader, !show);
    }
    private getFolderSecurity(sid: string) {
        return this.security.sharedFolderSecurity ? this.security.sharedFolderSecurity[sid] : null;
    }
    protected createColumnInfo() {
        const folderShareHeader: Dom.Content = ["Via folder share"];
        if (this.classInfo.projectPermissionName) {
            folderShareHeader.push(
                Dom.node(
                    new Icon("info-circle-20 margin-left-4", {
                        tooltip:
                            "Inactive folder permissions require the recipient to have Receive "
                            + this.classInfo.projectPermissionName
                            + " permissions to view this "
                            + this.classInfo.displayName
                            + " in folders",
                        tooltipPosition: ["below-centered"],
                    }),
                ),
            );
        }
        this.columnInfo = [
            {
                header: "Name",
                props: { style: { width: BaseObjectTable.nameColumnWidth + "px" } },
                cellCallback: (p) => p.o.nameCell.cellCallback(p),
            },
            {
                header: "Summary",
                props: { style: { width: "115px" } },
                cellCallback: (p) => p.o.summaryPermCell.cellCallback(p),
            },
            {
                header: "Via direct share",
                props: { style: { width: "115px" } },
                cellCallback: (p) => p.o.directPermCell.cellCallback(p),
            },
            {
                header: "",
                props: { style: { width: "65px" } },
                cellCallback: (p) => p.o.revokeCell.cellCallback(p),
            },
            {
                header: folderShareHeader,
                props: {},
                cellCallback: (p) => p.o.folderSharePermCell.cellCallback(p),
            },
        ];
    }
    protected override onDirectPermChangeComplete(row: SidRow, index: number): void {
        super.onDirectPermChangeComplete(row, index);
        this.updateSummaryPermCell(row);
    }
    /** Callback when the backend permission revoke has succeeded. */
    protected override onRevokeComplete(row: SidRow): boolean {
        const deleted = super.onRevokeComplete(row);
        if (!deleted) {
            // The recipient still has folder perms so update the summary.
            this.updateSummaryPermCell(row);
        }
        return deleted;
    }
    protected override shouldDeleteOnRevoke(row: SidRow): boolean {
        // If the recipient has folder permissions, don't delete the row.
        const folderSecurity = this.getFolderSecurity(row.sid);
        return !ShareableObject.hasPerms(folderSecurity);
    }
    private updateSummaryPermCell(row: SidRow) {
        row.summaryPermCell.rebuild(this.securityMap, row.getSummaryPermIndex());
    }
    protected override rowCmp(row1: ObjectTableRow, row2: ObjectTableRow): number {
        // Filter entries with no access to the bottom.
        const index1 = row1.summaryPermCell.curIndex;
        const index2 = row2.summaryPermCell.curIndex;
        if (index1 < 0 && index2 >= 0) {
            return 1;
        } else if (index1 >= 0 && index2 < 0) {
            return -1;
        } else {
            return super.rowCmp(row1, row2);
        }
    }
}

/**
 * An object table for homepage folders. Includes a column for the permissions on the objects in
 * the folder. The user is not able to grant object permissions greater than their own.
 */
class HomepageFolderTable extends BaseObjectTable {
    protected override obj: HomepageFolder;
    constructor(params: BaseObjectTableParams) {
        super(params);
    }
    protected override init(params: BaseObjectTableParams): void {
        super.init(params);
        Dom.addClass(this.tableContainer, "homepage-folder-table");
        const headingDiv = Dom.div({ class: "homepage-folder-table__heading" });
        if (this.obj.owner) {
            Dom.addContent(
                headingDiv,
                new Icon("user-20").node,
                Dom.span({ class: "margin-right-12" }, this.obj.owner.display()),
            );
        }
        if (this.obj.created) {
            Dom.addContent(
                headingDiv,
                new Icon("clock-20").node,
                DateUtil.displayFullDateLocal(this.obj.created)
                    + ", "
                    + DateUtil.displayTimeLocal(this.obj.created),
            );
        }
        this.setHeading(headingDiv);
    }
    override createDialog() {
        this.dialogWidth = 720;
        super.createDialog();
    }
    protected override createSidRow(sid: string, perms: Base.PermList): SidRow {
        const row = super.createSidRow(sid, perms);
        // Create the object permission cell.
        const objectSecurity = this.security.folderObjectSecurity
            ? this.security.folderObjectSecurity[sid]
            : null;
        // Folder owners don't have explicit object permissions, but have full access anyway.
        if (!row.isOwner && !objectSecurity) {
            // empty cell
            row.folderObjectPermCell = new PermissionCell();
        } else {
            const index = ShareableObject.getSecurityMapIndex(this.securityMap, objectSecurity);
            row.folderObjectPermCell = new PermissionCell({
                securityMap: ShareableObject.getFolderObjectSecurityMap(this.obj, index),
                initialIndex: row.isOwner ? this.securityMap.elems.length - 1 : index,
                onChange: this.canEditSidPerms(row)
                    ? (_info, index) => {
                          this.onObjectPermChange(row, index);
                      }
                    : null,
            });
        }
        return row;
    }
    protected override getTableParams(): Table.TableParams<ObjectTableRow, Table.RowData> {
        const params = <Table.TableParams<ObjectTableRow, Table.RowData>>super.getTableParams();
        params.maxHeight = "293px";
        return params;
    }
    protected override canEditOwnerPerms(): boolean {
        // You can never edit the permissions of folder owners.
        return false;
    }
    /** Callback to handle a change made to a recipient's folder object permissions. */
    private onObjectPermChange(row: SidRow, index: number): void {
        ShareableObject.setFolderObjectPerms(
            this.obj.id,
            row.nameCell.sid,
            row.folderObjectPermCell.securityMap.elems[index].id,
        )
            .then(() => {
                // The user isn't allowed to grant object permissions higher than their own. So, if the
                // recipient had a greater set of object permissions which have now been revoked, we
                // need to rebuild the selector with the more restricted set of permissions.
                const newSecurityMap = ShareableObject.getFolderObjectSecurityMap(this.obj, index);
                row.folderObjectPermCell.rebuild(newSecurityMap, index);
            })
            .catch((reason: Rest.Failed) =>
                this.onPermChangeError(reason, row.folderObjectPermCell),
            );
    }
    protected createColumnInfo() {
        const objectPermissionIcon = new Icon("info-circle-20");
        Dom.addClass(objectPermissionIcon, "margin-left-4");
        this.toDestroy.push(
            new Tooltip(
                objectPermissionIcon,
                "This permission will only apply to objects added to the folder after the permission is updated",
            ),
        );
        this.columnInfo = [
            {
                header: "Name",
                props: { style: { width: "190px" } },
                cellCallback: (p) => p.o.nameCell.cellCallback(p),
            },
            {
                header: [Dom.div({}, "Permission on folder")],
                props: { style: { width: "184px" } },
                cellCallback: (p) => p.o.directPermCell.cellCallback(p),
            },
            {
                header: [
                    Dom.div({}, "Permission on objects in folder", Dom.node(objectPermissionIcon)),
                ],
                props: { style: { width: "248px" } },
                cellCallback: (p) => p.o.folderObjectPermCell.cellCallback(p),
            },
            {
                header: "Revoke",
                props: {},
                cellCallback: (p) => p.o.revokeCell.cellCallback(p),
            },
        ];
    }
}

export function showObjectDialog(
    obj: Base.Object,
    security?: ShareableObject.ObjectSecurity,
): void {
    const showDialog = () => {
        switch (obj.className) {
            case "PredictionModel":
            case "SavedResultsTableView":
            case "WritingAssistantTemplate":
                new NonFolderableObjectTable({ obj, security });
                break;
            case "HomepageFolder":
                new HomepageFolderTable({ obj, security });
                break;
            default:
                new FolderableObjectTable({ obj, security });
                break;
        }
    };

    if (security) {
        showDialog();
    } else {
        ShareableObject.getSecurity(obj).then((data: ShareableObject.ObjectSecurity) => {
            security = data;
            showDialog();
        });
    }
}

// ************************************
//
// A "recipient table" shows all the objects for which a specific recipient has directly-granted
// permissions. The rows represent either a specific object or a class (permission set) of objects.
//
// ************************************

/**
 * Base row class used for the recipient table's object store.
 */
class RecipientTableRow extends BaseTableRow {
    permSet: Security.ProjectPermSet;
    // If the row represents an object, this is its className.
    objClass: string;
    // If the row represents an object, this is its ID.
    objId: string | number;
    // If the row represents a Storybuilder object, this is its chron ID. Orphan drafts do not have
    // a chron ID.
    chronId: number;
    // If the row represents a Storybuilder object, this is the index of the object's chronology
    // used for sorting purposes. Orphan drafts are listed last.
    chronIndex: number;
    static ORPHAN_DRAFT_CHRON_INDEX = Number.MAX_VALUE;
    isPermSet(): boolean {
        return !this.objClass && this.chronIndex !== RecipientTableRow.ORPHAN_DRAFT_CHRON_INDEX;
    }
    isObject(): boolean {
        return !!this.objClass;
    }
    isStorybuilder(): boolean {
        return Is.defined(this.chronIndex);
    }
    isChron(): boolean {
        return this.objClass === "Chronology";
    }
    isChronObject(): boolean {
        return this.isObject() && !this.isChron() && !!this.chronId;
    }
    isOrphanDraftPlaceholder() {
        return !this.objClass && this.chronIndex === RecipientTableRow.ORPHAN_DRAFT_CHRON_INDEX;
    }
    isOrphanDraft() {
        return !!this.objClass && this.chronIndex === RecipientTableRow.ORPHAN_DRAFT_CHRON_INDEX;
    }
}

/**
 * A name cell used for a project permission set in a RecipientTable (STRs, Storybuilder, etc.).
 */
class PermSetNameCell extends NameCell {
    icon: Icon;
    count: number;
    countDiv: HTMLElement;
    constructor(public permSet: Security.ProjectPermSet) {
        super({ name: permSet.objsDisplay() });
    }
    collapse(collapse: boolean) {
        const addClass = collapse ? "icon_caret-right-20" : "icon_caret-down-20";
        const removeClass = collapse ? "icon_caret-down-20" : "icon_caret-right-20";
        Dom.replaceClass(this.icon.node, addClass, removeClass);
    }
    toggle(): boolean {
        const collapsed = Dom.hasClass(this.icon.node, "icon_caret-right-20");
        this.collapse(!collapsed);
        return collapsed;
    }
    setCount(count: number) {
        this.count = count;
        Dom.setContent(this.countDiv, count.toString());
        if (!count) {
            this.collapse(true);
            this.setDisabled(true);
        }
    }
    protected override buildContent(): void {
        super.buildContent();
        Dom.addClass(this.td, "name-column__perm-set");
        Dom.place((this.icon = new Icon("caret-right-20")), this.container, "first");
        Dom.place(
            Dom.div(
                { class: "name-column__perm-set-count" },
                Dom.span("("),
                (this.countDiv = Dom.span()),
                Dom.span(")"),
            ),
            this.container,
            "last",
        );
    }
}

class PermSetRow extends RecipientTableRow {
    override nameCell: PermSetNameCell;
}

/**
 * A name cell used for an object in a RecipientTable.
 */
class ObjectNameCell extends NameCell {
    constructor(public permInfo: ShareableObject.RecipientPermInfo) {
        super({
            name:
                (permInfo.className === "Chronology"
                    ? "[Story] "
                    : permInfo.className === "Deposition"
                      ? "[Deposition] "
                      : permInfo.className === "Argument"
                        ? "[Draft] "
                        : "") + permInfo.display,
        });
    }
    protected override buildContent(): void {
        super.buildContent();
        Dom.addClass(this.td, "name-column__object " + "name-column__" + this.permInfo.className);
    }
}

class ObjectRow extends RecipientTableRow {
    override nameCell: ObjectNameCell;
}

/**
 * A name cell used for the placeholder row for orphan drafts.
 */
class OrphanDraftNameCell extends NameCell {
    constructor() {
        super({ name: "Drafts unassigned to a Story" });
    }
    protected override buildContent(): void {
        super.buildContent();
        Dom.addClass(this.td, "name-column__orphan-drafts");
    }
}

interface RecipientTableColumnInfo extends TableColumnInfo {
    cellCallback: Table.CellCallback<RecipientTableRow, Table.RowData>;
}

interface RecipientTableParams extends BaseTableParams {
    recipient: Recipient;
    permData: { [permSet: string]: ShareableObject.RecipientPermInfo[] };
}

export class RecipientTable extends BaseTable {
    protected override rows: RecipientTableRow[];
    protected override table: Table<RecipientTableRow, Table.RowData>;
    protected override columnInfo: RecipientTableColumnInfo[];
    private recipient: Recipient;
    private sid: string;
    private recipientType: string;
    private permData: { [permSetId: string]: ShareableObject.RecipientPermInfo[] };
    private idToPermSet: Map<string, { permSet: Security.ProjectPermSet; row: PermSetRow }>;
    private objRowsByPermSetId: Map<string, RecipientTableRow[]>;
    private visiblePermSets: Set<string>;
    private chronRows: Map<number, ObjectRow>;
    private orphanDraftPlaceholderRow: RecipientTableRow;
    constructor(params: RecipientTableParams) {
        super(params);
        this.idToPermSet.forEach((info) => this.updatePermSetCount(info.row));
    }
    protected override init(params: RecipientTableParams): void {
        super.init(params);
        Dom.addClass(this.tableContainer, "recipient-table");
        this.idToPermSet = new Map();
        this.objRowsByPermSetId = new Map();
        this.visiblePermSets = new Set();
        this.chronRows = new Map();
        this.recipient = params.recipient;
        this.sid = params.recipient.sid();
        this.recipientType = params.recipient.recipientType();
        this.permData = params.permData;
        this.setHeading(
            "Only directly-shared objects are displayed here. "
                + "Objects the "
                + this.recipientType
                + " has access to via folders are not shown.",
        );
    }
    protected createRows(): void {
        const chronIdToIndex: Map<number, number> = new Map();
        const permSets = Base.get(Security.ProjectPermSet);

        // Create the PermSet entries:
        permSets.forEach((permSet, i) => {
            const row = new PermSetRow(i);
            row.permSet = permSet;
            row.nameCell = new PermSetNameCell(permSet);
            row.directPermCell = new PermissionCell();
            row.revokeCell = new RevokeCell({
                onRevoke: () => this.onRevokePermSet(row),
                tooltip: "Revoke permissions on all " + permSet.objsDisplay(),
                disabledTooltip: "No permissions to revoke",
            });
            this.rows.push(row);
            this.objRowsByPermSetId.set(permSet.id, []);
            this.idToPermSet.set(permSet.id, { permSet, row });
        });

        // Create the object entries for each permSet:
        Object.entries(this.permData).forEach(([permSetId, permInfoList]) => {
            const permSet = this.idToPermSet.get(permSetId).permSet;
            const sortKey = Arr.indexOf(permSets, permSet);
            const isStorybuilder = permSetId === Security.STORYBUILDER_PERMSET_ID;
            if (isStorybuilder) {
                // Sort the chronologies by display name and assign them indexes based on the sort.
                Arr.sort(
                    permInfoList.filter((permInfo) => permInfo.className === "Chronology"),
                    {
                        cmp: (a, b) => Cmp.str(a.display, b.display),
                    },
                ).forEach((permInfo, i) => chronIdToIndex.set(permInfo.id, i));
            }
            const rows: RecipientTableRow[] = [];
            permInfoList.forEach((permInfo) => {
                const hasPerms = ShareableObject.hasPerms(permInfo.perms);
                const row = new ObjectRow(sortKey);
                row.permSet = permSet;
                row.objClass = permInfo.className;
                row.objId = permInfo.id;
                if (isStorybuilder) {
                    row.chronId = row.isChron() ? row.objId : permInfo.chronId;
                    row.chronIndex = row.chronId
                        ? chronIdToIndex.get(row.chronId)
                        : // Orphaned drafts don't have a chron ID.
                          RecipientTableRow.ORPHAN_DRAFT_CHRON_INDEX;
                }
                row.nameCell = new ObjectNameCell(permInfo);
                if (hasPerms) {
                    const securityMap = ShareableObject.getClassInfo(
                        permInfo.className,
                    ).securityMap;
                    row.directPermCell = new PermissionCell({
                        securityMap,
                        initialIndex: ShareableObject.getSecurityMapIndex(
                            securityMap,
                            permInfo.perms,
                        ),
                        onChange: (_info, index) => {
                            this.onDirectPermChange(row.objClass, row.objId, this.sid, row, index);
                        },
                    });
                } else {
                    row.directPermCell = new PermissionCell();
                }
                let disabledTooltip: string;
                if (row.isChron()) {
                    this.chronRows.set(row.chronId, row);
                    if (hasPerms) {
                        disabledTooltip =
                            "You cannot revoke Story permissions while the "
                            + this.recipientType
                            + " has permissions on any of the Story's "
                            + "Depositions or Drafts";
                    } else {
                        disabledTooltip = `The ${this.recipientType} has no permissions on this Story`;
                    }
                }
                row.revokeCell = new RevokeCell({
                    onRevoke: () => {
                        this.onDirectPermRevoke(permInfo.className, permInfo.id, this.sid, row);
                    },
                    disabledTooltip,
                    disabled: !!disabledTooltip,
                });
                this.rows.push(row);
                rows.push(row);
            });
            this.objRowsByPermSetId.set(permSetId, rows);

            if (isStorybuilder) {
                // Create the placeholder entry for orphaned drafts.
                this.orphanDraftPlaceholderRow = new RecipientTableRow(
                    Arr.indexOf(
                        permSets,
                        this.idToPermSet.get(Security.STORYBUILDER_PERMSET_ID).permSet,
                    ),
                );
                this.orphanDraftPlaceholderRow.permSet = permSet;
                this.orphanDraftPlaceholderRow.chronIndex =
                    RecipientTableRow.ORPHAN_DRAFT_CHRON_INDEX;
                this.orphanDraftPlaceholderRow.nameCell = new OrphanDraftNameCell();
                this.orphanDraftPlaceholderRow.directPermCell = new PermissionCell();
                this.orphanDraftPlaceholderRow.revokeCell = new RevokeCell();
                this.rows.push(this.orphanDraftPlaceholderRow);
                rows.push(this.orphanDraftPlaceholderRow);

                // Update state.
                this.updateOrphanDraftPlaceholder();
                this.chronRows.forEach((chronRow) => this.updateChronRowState(chronRow));
            }
        });
    }
    protected createColumnInfo(): void {
        this.columnInfo = [
            {
                header: "Object",
                props: { style: { width: "400px" } },
                cellCallback: (p) => p.o.nameCell.cellCallback(p),
            },
            {
                header: "Permission",
                props: { style: { width: "140px" } },
                cellCallback: (p) => p.o.directPermCell.cellCallback(p),
            },
            {
                header: "Revoke",
                props: {},
                cellCallback: (p) => p.o.revokeCell.cellCallback(p),
            },
        ];
    }
    protected override getTableParams(): Table.TableParams<RecipientTableRow, Table.RowData> {
        const params = <Table.TableParams<RecipientTableRow, Table.RowData>>super.getTableParams();
        params.onClick = (row, _rowNum, colNum, _evt, _me) => {
            if (row.isPermSet() && colNum === 0 && !row.nameCell.disabled) {
                this.onPermSetClick(<PermSetRow>row);
            }
        };
        params.maxHeight = "382px";
        return params;
    }
    private onPermSetClick(row: PermSetRow): void {
        const expanded = this.visiblePermSets.has(row.permSet.id);
        row.nameCell.collapse(expanded);
        if (expanded) {
            this.visiblePermSets.delete(row.permSet.id);
        } else {
            this.visiblePermSets.add(row.permSet.id);
        }
        this.table.update(this.objRowsByPermSetId.get(row.permSet.id));
    }
    /**
     * Check if there are any more permissioned orphan drafts. If not, hide the placeholder.
     * Returns true if the placeholder was hidden.
     */
    private updateOrphanDraftPlaceholder(): boolean {
        if (
            this.orphanDraftPlaceholderRow
            && !this.revoked.has(this.orphanDraftPlaceholderRow)
            && !this.objRowsByPermSetId.get(Security.STORYBUILDER_PERMSET_ID).some((r) => {
                return r.isOrphanDraft() && !this.revoked.has(r);
            })
        ) {
            this.revoked.add(this.orphanDraftPlaceholderRow);
            return true;
        }
        return false;
    }
    /**
     * Check if there are any more permissioned objects for this chron. If not, either enable
     * revoking the chron's permissions (if the user has any), or hide the chron altogether (if the
     * user doesn't). Returns true if the chron was hidden.
     */
    private updateChronRowState(chronRow: ObjectRow): boolean {
        if (
            !this.objRowsByPermSetId.get(Security.STORYBUILDER_PERMSET_ID).some((r) => {
                return r !== chronRow && r.chronId === chronRow.chronId && !this.revoked.has(r);
            })
        ) {
            if (chronRow.directPermCell.editable) {
                chronRow.revokeCell.setDisabled(false);
            } else {
                this.revoked.add(chronRow);
                return true;
            }
        }
        return false;
    }
    protected override onRevokeComplete(row: RecipientTableRow): boolean {
        const hidden = super.onRevokeComplete(row);
        // If the row is an orphan draft, check if we need to hide the placeholder.
        if (row.isOrphanDraft() && this.updateOrphanDraftPlaceholder()) {
            // The orphan draft placeholder was hidden so update the table.
            this.table.update(this.orphanDraftPlaceholderRow);
        }
        // If the row is a chron object, check if we need to update the chron row's state.
        if (row.isChronObject()) {
            const chronRow = this.chronRows.get(row.chronId);
            if (this.updateChronRowState(chronRow)) {
                // The chron row was hidden so update the table.
                this.table.update(chronRow);
            }
        }
        this.updatePermSetCount(this.idToPermSet.get(row.permSet.id).row);
        return hidden;
    }
    private onRevokePermSet(row: PermSetRow): void {
        ShareableObject.revokeProjectPermSetPermissions([this.recipient.sid()], [row.permSet.id])
            .then(() => {
                this.objRowsByPermSetId.get(row.permSet.id).forEach((r) => this.revoked.add(r));
                // No need to update the table if this perm set was already collapsed.
                if (this.visiblePermSets.has(row.permSet.id)) {
                    this.visiblePermSets.delete(row.permSet.id);
                    this.table.update(this.objRowsByPermSetId.get(row.permSet.id));
                }
                this.updatePermSetCount(row);
            })
            .catch((reason: Rest.Failed) => {
                BaseTable.showErrorDialog(reason);
            });
    }
    private updatePermSetCount(row: PermSetRow): void {
        const count = this.objRowsByPermSetId.get(row.permSet.id).filter((r) => {
            return r.directPermCell.editable && !this.revoked.has(r);
        }).length;
        row.nameCell.setCount(count); // handles icon change and disabling if count is 0
        row.revokeCell.setDisabled(!count);
    }
    protected override rowFilter(row: RecipientTableRow): boolean {
        return (
            super.rowFilter(row) && (row.isPermSet() || this.visiblePermSets.has(row.permSet.id))
        );
    }
    protected override rowCmp(row1: RecipientTableRow, row2: RecipientTableRow): number {
        // If the rows belong to different permsets, sort on that.
        if (row1.sortKey !== row2.sortKey) {
            return Cmp.num(row1.sortKey, row2.sortKey);
        }
        // If one of the rows is the permset entry, put it first.
        if (row1.isPermSet() || row2.isPermSet()) {
            return row1.isPermSet() ? -1 : 1;
        }
        if (row1.isStorybuilder()) {
            // If the rows have different chron indexes, sort on that.
            if (row1.chronIndex !== row2.chronIndex) {
                return Cmp.num(row1.chronIndex, row2.chronIndex);
            }
            // If one of the rows is the chronology or orphan draft placeholder, put it first.
            if (row1.isChron() || row1.isOrphanDraftPlaceholder()) {
                return -1;
            }
            if (row2.isChron() || row2.isOrphanDraftPlaceholder()) {
                return 1;
            }
        }
        // Just sort by name. Since we use [Deposition] and [Draft] prefix qualifiers in the name,
        // this will automatically put depositions and drafts together under Storybuilder.
        return Cmp.str(row1.nameCell.name, row2.nameCell.name);
    }
}
