import * as Bugsnag from "Everlaw/Bugsnag";

const SUPPORT_ARTICLE_URL = "https://support.everlaw.com/api/v2/help_center/articles/search.json";
const SUPPORT_VIDEO_URL = "https://api.vimeo.com/me/videos";
const VIMEO_TOKEN = "7f7974e86270d4835de669b4aa70ccb7";
const EMBED_URL = "https://player.vimeo.com/video/";

interface ArticleQuery {
    query?: string;
    labelName?: string;
}

export interface Article {
    body: string;
    html_url: string;
    title: string;
    label_names: string[];
}

/**
 * In the instance {@link fetch} throws an error, we'll either ignore it or send it to
 * Bugsnag, depending on the error type. A `TypeError` is indicative of a network failure, so we
 * ignore it [1]. Though annoyingly, it could be indicative of other errors, but from the docs
 * it seems we don't need to really worry about running into those.
 *
 * [1]: https://developer.mozilla.org/en-US/docs/Web/API/Window/fetch#typeerror
 */
function handleFetchError(e: unknown, fetchType: string): void {
    // Fetch throws a TypeError in the instance of a network failure.
    if (!(e instanceof TypeError)) {
        Bugsnag.notify(Error(`${fetchType} fetch failed with error ${e}`));
    }
}

export async function fetchSupportArticles(params: ArticleQuery): Promise<Article[]> {
    const articleUrl = new URL(SUPPORT_ARTICLE_URL);
    if (params.query) {
        articleUrl.searchParams.set("query", params.query);
    }
    if (params.labelName) {
        articleUrl.searchParams.set("label_names", params.labelName);
    }
    let response = null;
    try {
        response = await fetch(articleUrl);
    } catch (e) {
        handleFetchError(e, "Article");
    }
    // If we run into an error fetching, there's not much we can do, but we should prevent the
    // error from breaking other site functionality.
    if (!response || response.status !== 200) {
        return [];
    }
    const data = await response.json();
    return data.results;
}

interface VideoQuery {
    query: string;
    videosPerPage: number;
}

/**
 * Represents a response from querying {@link SUPPORT_VIDEO_URL}.
 */
export interface VideoData {
    description: string | null;
    uri: string;
    pictures: {
        sizes: { link: string }[];
    };
    name: string;
    link: string;
    /**
     * This is not sent by {@link SUPPORT_VIDEO_URL}'s API response. Instead, we compute it from
     * {@link uri} after receiving the response.
     */
    embeddedVideoURL: string;
}

export async function fetchSupportVideos(params: VideoQuery): Promise<VideoData[]> {
    const videoUrl = new URL(SUPPORT_VIDEO_URL);
    videoUrl.searchParams.set("query", params.query.toLowerCase());
    videoUrl.searchParams.set("per_page", params.videosPerPage.toString());
    let response = null;
    try {
        response = await fetch(videoUrl, { headers: { Authorization: `Bearer ${VIMEO_TOKEN}` } });
    } catch (e) {
        handleFetchError(e, "Video");
    }
    // If we run into an error fetching, there's not much we can do, but we should prevent the
    // error from breaking other site functionality.
    if (!response || response.status !== 200) {
        return [];
    }
    const data = await response.json();
    return data.data.map((videoData: VideoData) => {
        videoData.embeddedVideoURL = `${EMBED_URL}${videoData.uri.split("/")[2]}`;
        return videoData;
    });
}
